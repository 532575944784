import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/seo'

import SkyViewer from '../../data/deep-sky/sky-viewer'

export default function Template (props) {
  const { data } = props
  const { mdx } = data
  const { frontmatter, body } = mdx
  const { title, metaDescription, hideImageInPage, thumbnail, lang, headerComponent } = frontmatter
  return (
    <>
      <SEO
        pageContext={props.pageContext}
        title={title}
        description={metaDescription} />

      <div className='blog-post-container'>
        <article className='post'>
          {/* {!thumbnail && (
            <div className='post-thumbnail' />
          )} */}
          {!hideImageInPage && !!thumbnail && (
            <div className='post-thumbnail' style={{ backgroundImage: `url(${thumbnail})` }} />
          )}
          {headerComponent === 'SkyViewer' && <SkyViewer language={lang} />}
          <div className='blog-post-content'>
            <h1 className='post-title'>{title}</h1>
            <MDXRenderer >{body}</MDXRenderer>
          </div>
        </article>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        path
        title
        lang
        thumbnail
        hideImageInPage
        headerComponent
      }
    }
  }
`
